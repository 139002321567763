'use client';
import React from 'react';

import { PrimaryButton } from '@bloom/ui/components/PrimaryButton';

import { HMega } from '@bloom/library/components/Typography/HMega';

import highRez1 from '@bloom/portal/assets/images/404/404_desktop.png';
import highRez2 from '@bloom/portal/assets/images/404/404_ipad_landscape.png';
import highRez3 from '@bloom/portal/assets/images/404/404_ipad_portrait.png';
import highRez4 from '@bloom/portal/assets/images/404/404_iphone.png';

const NotFound: React.FC = () => {
  function handleClick() {
    window.location.href = '/';
  }

  return (
    <div
      className="flex h-screen w-screen flex-col items-center justify-center gap-12 p-6 text-white sm:p-12"
      style={{ backgroundImage: 'linear-gradient(#b6b5ba, #2f261f)' }}
    >
      <img
        alt="404"
        className="absolute inset-0 h-full w-full object-cover"
        sizes="(max-width: 600px) 100vw, (max-width: 800px) 50vw, 25vw"
        src={highRez1.src}
        srcSet={`${highRez4.src} 400w, ${highRez3.src} 600w, ${highRez2.src} 800w, ${highRez1.src} 1000w"`}
      />

      <HMega className="relative z-10 text-4xl text-white">Oops!</HMega>
      <p className="font-regular relative z-10 w-[540px] max-w-full text-center text-base text-white">
        We can't find the page you're looking for or you're trying to make something happen that
        shouldn't happen.
      </p>
      <PrimaryButton className="relative z-10" data-testid="go-home-button" onClick={handleClick}>
        Go Home
      </PrimaryButton>
    </div>
  );
};

export default NotFound;
